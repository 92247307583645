import WebServiceRequest from '../Api/WebServiceRequest'

class PageElementCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('PageElement/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class PageElementGetPageElementByPageIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('PageElement/GetPageElementsByPageId')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class PageElementUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('PageElement/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
export {
    PageElementCreateRequest,
    PageElementGetPageElementByPageIdRequest,
    PageElementUpdateRequest,
}